<template>
    <section class="centerbox">
      <!--工具条-->
      <toolbar :buttonList="buttonList" @callFunction="callFunction"></toolbar>
  
      <!--列表-->
      <el-table
        :data="dtlist"
        v-loading="listLoading"
        @select="dialogCheck"
        @row-click="selectCurrentRow"
        ref="table"
        border=""
        style="width: 100%"
        class="custom-tbl"
      >
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column type="index" width="80"></el-table-column>
        <el-table-column
          prop="Name"
          label="类型名称"
          width
          sortable
        ></el-table-column> 
        <el-table-column
          prop="ComputeTypeSName"
          label="测算方法"
          width
          sortable
        ></el-table-column>
        <el-table-column
          prop="CreateTime"
          label="创建时间"
          :formatter="formatCreateTime"
          width
          sortable
        ></el-table-column>  
        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button size="small" @click="drawershow(scope.$index, scope.row)">编辑参数</el-button> 
          </template>
        </el-table-column>
      </el-table>
  
      <!--工具条-->
      <el-col :span="24" class="toolbar">
        <el-pagination
          layout="prev, pager, next"
          @current-change="handleCurrentChange"
          :page-size="50"
          :total="total"
          style="float: left"
        ></el-pagination>
      </el-col>
  
      <!--编辑界面-->
      <el-dialog
        title="编辑"
        :visible.sync="editFormVisible"
        v-model="editFormVisible"
        :close-on-click-modal="false"
      >
        <el-form
          :model="editForm"
          label-width="80px"
          :rules="editFormRules"
          ref="editForm"
        >
          <el-form-item label="类型名称" prop="Name">
            <el-input
              disabled
              v-model="editForm.Name"
              auto-complete="off"
            ></el-input>
          </el-form-item>  
          <el-form-item label="测算方法" prop="ComputeTypeName" >
                            <el-select 
                                v-model="editForm.ComputeTypeName" 
                                multiple
                                filterable  
                                default-first-option
                                placeholder="测算方法" 
                                style="width:100%">
                                <el-option
                                    v-for="item in computelist"
                                    :key="item.Id"
                                    :label="item.Name"
                                    :value="item.Id">
                                </el-option>
                            </el-select> 
                        </el-form-item> 
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="editFormVisible = false">取消</el-button>
          <el-button
            type="primary"
            @click.native="editSubmit"
            :loading="editLoading"
            >提交</el-button
          >
        </div>
      </el-dialog>
  
      <!--新增界面-->
      <el-dialog
        title="新增"
        :visible.sync="addFormVisible"
        v-model="addFormVisible"
        :close-on-click-modal="false"
      >
        <el-form
          :model="addForm"
          label-width="80px"
          :rules="addFormRules"
          ref="addForm"
        >
          <el-form-item label="类型名称" prop="Name">
            <el-input v-model="addForm.Name" auto-complete="off"></el-input>
          </el-form-item> 
          <el-form-item label="测算方法" prop="ComputeTypeName" >
                            <el-select 
                                v-model="addForm.ComputeTypeName" 
                                multiple
                                filterable 
                                allow-create
                                default-first-option
                                placeholder="测算方法" 
                                style="width:100%">
                                <el-option
                                    v-for="item in computelist"
                                    :key="item.Id"
                                    :label="item.Name"
                                    :value="item.Id">
                                </el-option>
                            </el-select> 
                        </el-form-item> 
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click.native="addFormVisible = false">取消</el-button>
          <el-button
            type="primary"
            @click.native="addSubmit"
            :loading="addLoading"
            >提交</el-button
          >
        </div>
      </el-dialog>
      <el-drawer
    :title="drawername"
    :visible.sync="drawer"
    :direction="direction" size="60%"  >
    <AssetTypeValue v-bind:Valueattribute="valueattribute"></AssetTypeValue>
  </el-drawer>
    </section> 
   
  </template>
 
  <script>
  import util from "../../../util/date";
  
  import {
    getAssetTypeListPage,
    removeAssetType,
    editAssetType,
    addAssetType, 
    getComputeTypeListPage,
    getAssetTypeValueListPage2,
    addSysOperateLog
  } from "../../apis/apis";
  import { getButtonList } from "../../promissionRouter";
  import Toolbar from "../../components/Toolbar";
  import AssetTypeValue from "./AssetTypeValue";
  import routes from "../../router/index";

  export default {
    components: { Toolbar,AssetTypeValue },
    
    data() {
      return {
        filters: {
          name: "",
        },
        buttonList: [],
        options: [],
        dtlist: [],
        defaultProps: {
          children: "children",
          label: "label",
        }, 
        total: 0,
        page: 1,
        listLoading: false,
        sels: [], //列表选中列
        currentRow: null,
        addDialogFormVisible: false,
        editFormVisible: false, //编辑界面是否显示
        editLoading: false,
        editFormRules: {
          Name: [{ required: true, message: "请输入类型名称", trigger: "blur" }], 
        },
        //编辑界面数据
        editForm: {
          Id: 0, 
          Name: "",  
          ComputeTypeIds: "",
          ComputeTypeName:[]
        },
  
        addFormVisible: false, //新增界面是否显示
        addLoading: false,
        addFormRules: {
          Name: [{ required: true, message: "请输入类型名称", trigger: "blur" }], 
        },
        //新增界面数据
        addForm: {
          Name: "",  
          ComputeTypeIds: "",
          ComputeTypeName:[]
        },
        isResouceShow: 0,
        //测算方法列表
        computelist:[],
        drawer:false,
        direction: 'rtl',
        drawername:'',
        valueattribute:{tid:0,list:[],count:0}
      };
    },
    computed: {
       
    },
    methods: { 
      drawershow(index,row){ 
        var m = Object.assign({}, row);
        this.drawername=m.Name; 
        this.valueattribute.tid = m.Id;

        //操作日志
        let param = {  ExternalId: m.Id,Name:routes.history.current.name+"-编辑参数" };
        addSysOperateLog(param);

        let para = {
          page: 1, 
          assetTypeId:m.Id
        }; 

        console.log(1)
        getAssetTypeValueListPage2(para).then((res) => {
          console.log(res)
          this.valueattribute.count = res.data.response.dataCount;
          this.valueattribute.list = res.data.response.data; 
        }); 
        console.log(2)
        this.drawer = true;
      },
      dialogCheck(selection, row) {
        this.currentRow = null;
        this.$refs.table.clearSelection();
        if (selection.length === 0) {
          return;
        }
        if (row) {
          this.selectCurrentRow(row);
        }
      },
      selectCurrentRow(val) {
        if (val) {
          this.currentRow = val;
          this.$refs.table.clearSelection();
          this.$refs.table.toggleRowSelection(val, true);
        }
      },
      callFunction(item) {
        this.filters = {
          name: item.search,
        };
        this[item.Func].apply(this, item);
      },
       
      formatCreateTime: function (row, column) {
        return !row.CreateTime || row.CreateTime == ""
          ? ""
          : util.formatDate.format(new Date(row.CreateTime), "yyyy-MM-dd");
      },
      handleCurrentChange(val) {
        this.page = val;
        this.getAssetTypes();
      },
      //获取测算列表
      getcomputelist(){ 
        let para = {
          page: this.page,
          key: this.filters.name,
        };
        getComputeTypeListPage(para).then((res) => {
          this.computelist = res.data.response.data; 
          this.getAssetTypes();
        }); 
      }, 
      getAssetTypes() {
        let _this = this;
        let para = {
          page: this.page,
          key: this.filters.name,
        };
        this.listLoading = true;

        getAssetTypeListPage(para).then((res) => {
          this.total = res.data.response.dataCount;
          this.dtlist = res.data.response.data;
          this.dtlist.forEach(item=>{
            var cnames = [];
            if(item.ComputeTypeIds!=null&&item.ComputeTypeIds.length>0){
              var ids = item.ComputeTypeIds.split(','); 
              ids.forEach(item2=>{
                var m = this.computelist.find(item3=>item3.Id==item2);
                if(m!=null){
                  cnames.push(m.Name);
                } 
              })
            }
            item.ComputeTypeName =[];
            item.ComputeTypeSName= cnames.join(',');
          }) 
          this.listLoading = false;
          
        });
      },
      //删除
      handleDel() {
        let row = this.currentRow;
        if (!row) {
          this.$message({
            message: "请选择要删除的一行数据！",
            type: "error",
          });
  
          return;
        }
        this.$confirm("确认删除该记录吗?", "提示", {
          type: "warning",
        })
          .then(() => {
            this.listLoading = true;
            //NProgress.start();
            let para = { id: row.Id };
            removeAssetType(para).then((res) => {
              if (util.isEmt.format(res)) {
                this.listLoading = false;
                return;
              }
              this.listLoading = false;
              //NProgress.done();
              if (res.data.success) {
                this.$message({
                  message: "删除成功",
                  type: "success",
                });
              } else {
                this.$message({
                  message: res.data.msg,
                  type: "error",
                });
              }
  
              this.getAssetTypes();
              //操作日志
            let param = {  ExternalId:  res.data.response,Name:routes.history.current.name+"-删除" };
            addSysOperateLog(param);
            });
          })
          .catch(() => {});
      },
      //显示编辑界面
      handleEdit() {
        let row = this.currentRow;
        this.options = [];
        if (!row) {
          this.$message({
            message: "请选择要编辑的一行数据！",
            type: "error",
          });
  
          return;
        }
   
        this.editFormVisible = true;
        this.editForm = Object.assign({}, row);
        if(this.editForm.ComputeTypeIds.length>0){
          var ids =   [];
          var tids = this.editForm.ComputeTypeIds.split(',');
          tids.forEach(item=>{
            ids.push(parseInt(item))
          })

          this.editForm.ComputeTypeName = ids;
        }  
      },
      //显示新增界面
      handleAdd() {
        this.addFormVisible = true;
        this.options = [];
        this.addForm = { 
          Name: "", 
          ComputeTypeIds: "",
        };
   
      },
      //编辑
      editSubmit: function () {
        this.$refs.editForm.validate((valid) => {
          if (valid) {
            this.$confirm("确认提交吗？", "提示", {}).then(() => {
              this.editLoading = true;
              //NProgress.start();
              let para = Object.assign({}, this.editForm);
   
              if(para.ComputeTypeName.length>0){
                para.ComputeTypeIds = para.ComputeTypeName.join(",");
              }
   
              editAssetType(para).then((res) => {
                if (util.isEmt.format(res)) {
                  this.editLoading = false;
                  return;
                }
  
                if (res.data.success) {
                  this.editLoading = false; 
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["editForm"].resetFields();
                  this.editFormVisible = false;
                  this.getAssetTypes();
                     //操作日志
                 let param = {  ExternalId:  res.data.response,Name:routes.history.current.name+"-编辑" };
                addSysOperateLog(param);
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            });
          }
        });
      },
      //新增
      addSubmit: function () { 
        let _this = this;
        this.$refs.addForm.validate((valid) => {
          if (valid) {
            this.$confirm("确认提交吗？", "提示", {}).then(() => {
              this.addLoading = true;
              //NProgress.start();
              let para = Object.assign({}, this.addForm); 
              if(para.ComputeTypeName.length>0){
                para.ComputeTypeIds = para.ComputeTypeName.join(",");
              }
               
              var user = JSON.parse(window.localStorage.user);
  
              if (user && user.uID > 0) {
                para.CreateId = user.uID; 
              } else {
                this.$message({
                  message: "用户信息为空，先登录",
                  type: "error",
                });
                _this.$router.replace(
                  _this.$route.query.redirect ? _this.$route.query.redirect : "/"
                );
              }
  
             
              addAssetType(para).then((res) => {
                if (util.isEmt.format(res)) {
                  this.addLoading = false;
                  return;
                }
                if (res.data.success) {
                  this.addLoading = false;
                  //NProgress.done();
                  this.$message({
                    message: res.data.msg,
                    type: "success",
                  });
                  this.$refs["addForm"].resetFields();
                  this.addFormVisible = false;
                  this.getAssetTypes();
                  //操作日志
               let param = {  ExternalId: res.data.response,Name:routes.history.current.name+"-新增" };
                addSysOperateLog(param);
                } else {
                  this.$message({
                    message: res.data.msg,
                    type: "error",
                  });
                }
              });
            });
          }
        });
      },
      getButtonList2(routers) {
        let _this = this;
        routers.forEach((element) => {
          let path = this.$route.path.toLowerCase();
          if (element.path && element.path.toLowerCase() == path) {
            _this.buttonList = element.children;
            return;
          } else if (element.children) {
            _this.getButtonList(element.children);
          }
        });
      },
    },
    mounted() { 
      this.getcomputelist();  
        //操作日志
        let param = { Name:routes.history.current.name+"-列表" };
      addSysOperateLog(param);
      let routers = window.localStorage.router
        ? JSON.parse(window.localStorage.router)
        : []; 
   
      this.buttonList = getButtonList(this.$route.path, routers);
    },
  };
  </script>
  
  <style lang="less" scoped>
  .custom-tbl /deep/ .has-gutter .el-checkbox {
    display: none;
  }
  .centerbox{
  width: 100%;
  padding: 2rem;
  box-sizing: border-box;
  margin-bottom: 10rem;
}
  </style>
  